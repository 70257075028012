import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { Helmet } from "react-helmet"
import Header from "../components/header"
import Footer from "../components/footer"
import "../styles/style.css"
import Interflora from "../../static/images/casos/1-logo-interflora.png"
import LaLiga from "../../static/images/casos/4-logo-laliga.png"
import Telefonica from "../../static/images/casos/5-logo-telefonica.png"

const Empleo = () => {
  const data = useStaticQuery(graphql`
    query Images_casos {
      image: allFile(
        filter: { relativeDirectory: { eq: "casos" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `)
  return (
    <div>
      <Helmet>
        {/*Primary Meta Tags*/}
        <title>Casos de éxito | Qualoom</title>
        <meta name="title" content="Casos de éxito | Qualoom"/>
        <meta name="description" content="Nuestra mejor carta de presentación son nuestros clientes: Interflora, Vocento,La Liga o Telefonica entre otros, nuestra marca en quién confiar."/>
        <html lang='es'/>        
        <link rel="alternate" href="https://www.qualoom.es/casos-de-exito/"/>
        <link rel="canonical" href="https://www.qualoom.es/casos-de-exito/"/>
        <meta name="ahrefs-site-verification" content="11d569467b4aa0b0c9daa5b49b9f41eb8a413858d36c9f6ece089482f4d3b630"/>
        
        {/*Open Graph / Facebook*/}
        <meta property="og:locale" content="es_ES"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.qualoom.es/casos-de-exito/"/>
        <meta property="og:title" content="Casos de éxito | Qualoom"/>
        <meta property="og:description" content="Nuestra mejor carta de presentación son nuestros clientes: Interflora, Vocento,La Liga o Telefonica entre otros, nuestra marca en quién confiar."/>
        <meta property="og:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/7rqBAUMenOdC3IghGjnohN/4fa083ae3ef9264556ff9f15e042d70e/tw_cards_casos.png"/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="628"/>
        <meta property="fb:admins" content="338114870907726"/>
        
        {/*Twitter*/}
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://www.qualoom.es/casos-de-exito/"/>
        <meta property="twitter:title" content="Casos de éxito | Qualoom"/>
        <meta property="twitter:description" content="Nuestra mejor carta de presentación son nuestros clientes: Interflora, Vocento,La Liga o Telefonica entre otros, nuestra marca en quién confiar."/>
        <meta property="twitter:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/7rqBAUMenOdC3IghGjnohN/4fa083ae3ef9264556ff9f15e042d70e/tw_cards_casos.png"/>
        <meta property="twitter:creator" content="https://twitter.com/qualoom"/>

        <script type="application/ld+json">
        {`{"@type": "BreadcrumbsList", "@id":"https://www.qualoom.es/casos-de-exito/#breadcrumb",              
            "itemListElement":[
              {"@type":"ListItem", "position":"1", "name":"Inicio", "item": {"@id": "https://www.qualoom.es/", "name": "Inicio"}},
              {"@type":"ListItem", "position":"2", "name":"Casos de éxito", "item": {"@id": "https://www.qualoom.es/casos-de-exito/", "name": "Casos de éxito"}}
            ]}`}
        </script>
      </Helmet>
      <BackgroundImage fluid={data.image.nodes[0].childImageSharp.fluid}>
        <div className="background-casos">
          <div className="header_div"><Header /></div>
          <div className="main">
            <div className="empty"></div>
            <div className="text">
              <h1>Casos de éxito. Nuestra mejor carta de presentación son nuestros clientes</h1>
              <p>Somos una empresa que invierte muy poco en publicidad y posicionamiento. La confianza de nuestros socios y clientes, así como la recomendación de nuestra marca es la mejor carta de presentación.</p>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className="casos-content-main">
        <div className="casos-content">
          <div className="casos-text">
            <img src={Interflora} alt="Interflora"></img>
            <p>Qualoom ha sido el partner español de Amazon Web Services (AWS) seleccionado por Fleurop Interflora España S.A. para realizar la migración de su infraestructura a la nube y dar soporte continuado una vez en producción. Interflora es el líder en el negocio de la gestión de encargos florales y plantas naturales en España y una de las divisiones más significativas de la red global de Fleurop Inc.</p>
            <Link to="/caso-de-exito-interflora/"><button className="descubre">Descubre más</button></Link>
          </div>
          <div className="casos-image interflora"></div>
        </div>
        <div className="casos-content laliga-mobile">
          <img src={LaLiga} alt="LaLiga"></img>
          <div className="casos-text">
            <p>La Liga Fantasy, un fenómeno nacional e internacional al que se suman cada día miles de usuarios con la posibilidad de diseñar sus equipos y competir por ganar una de las ligas de futbol más importantes del mundo. Nuestro reto, operar y administrar la infraestructura 24x7x365 días al año con una disponibilidad del 99.99% a través de nuestro equipo de DevOps, SysOps en Amazon Web Services.</p>
          </div>
        </div>
        <div className="casos-content">
          <div className="casos-text">
            <img src={Telefonica} alt="Telefónica"></img>
            <p>Telefónica de España y su división de operaciones para la explotación de servicios OnPremise y Cloud (AWS y GCP) ha seleccionado a Qualoom Expertise Technology como proveedor de especializado en administración de sistemas, seguridad, DevOps y operaciones a gran escala, confiando año tras año y desde 2015 en el desempeño de una serie de competencias de alto valor añadido para la compañía en áreas críticas de negocio.</p>
          </div>
          <div className="casos-image telefonica"></div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Empleo
